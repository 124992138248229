import React from "react";
import classes from './FooterStyle.module.scss';
function Footer() {
  return (
    <footer className={classes.Footer}>
      <div className="footer-section">footer</div>
    </footer>
  );
}

export default Footer;
