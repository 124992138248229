import React, { useState } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasHeader,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
const data = [
  "RELIANCE INDUSTRIES LTD  ",
  "TATA CONSULTANCY SERV LT ",
  "HDFC BANK LTD            ",
  "HINDUSTAN UNILEVER LTD.  ",
  "ITC LTD                  ",
  "HDFC LTD                 ",
  "INFOSYS LIMITED          ",
  "STATE BANK OF INDIA      ",
  "KOTAK MAHINDRA BANK LTD  ",
  "ICICI BANK LTD.          ",
  "MARUTI SUZUKI INDIA LTD. ",
  "AXIS BANK LIMITED        ",
  "LARSEN & TOUBRO LTD.     ",
  "OIL AND NATURAL GAS CORP.",
  "WIPRO LTD                ",
  "BAJAJ FINANCE LIMITED    ",
  "HCL TECHNOLOGIES LTD     ",
  "COAL INDIA LTD           ",
  "ASIAN PAINTS LIMITED     ",
  "INDIAN OIL CORP LTD      ",
  "BHARTI AIRTEL LIMITED    ",
  "NTPC LTD                 ",
  "HINDUSTAN ZINC LIMITED   ",
  "SUN PHARMACEUTICAL IND L ",
  "POWER GRID CORP. LTD.    ",
  "BAJAJ FINSERV LTD.       ",
  "ULTRATECH CEMENT LIMITED ",
  "INDUSIND BANK LIMITED    ",
  "TITAN COMPANY LIMITED    ",
  "AVENUE SUPERMARTS LIMITED",
  "MAHINDRA & MAHINDRA LTD  ",
  "DABUR INDIA LTD          ",
  "BRITANNIA INDUSTRIES LTD ",
  "HDFC LIFE INS CO LTD     ",
  "BHARAT PETROLEUM CORP  LT",
  "GAIL (INDIA) LTD         ",
  "BAJAJ AUTO LIMITED       ",
  "VEDANTA LIMITED          ",
  "GODREJ CONSUMER PRODUCTS ",
  "TECH MAHINDRA LIMITED    ",
  "ADANI PORT & SEZ LTD     ",
  "JSW STEEL LIMITED        ",
  "SBI LIFE INSURANCE CO LTD",
  "BOSCH LIMITED            ",
  "PIDILITE INDUSTRIES LTD  ",
  "SHREE CEMENT LIMITED     ",
  "BHARTI INFRATEL LTD.     ",
  "TATA STEEL LIMITED       ",
  "TATA MOTORS LIMITED      ",
  "HERO MOTOCORP LIMITED    ",
  "EICHER MOTORS LTD        ",
  "BANDHAN BANK LIMITED     ",
  "GRASIM INDUSTRIES LTD    ",
  "MARICO LIMITED           ",
  "HINDALCO  INDUSTRIES  LTD",
  "AUROBINDO PHARMA LTD     ",
  "INTERGLOBE AVIATION LTD  ",
  "DR. REDDY'S LABORATORIES ",
  "YES BANK LIMITED         ",
  "HAVELLS INDIA LIMITED    ",
  "MOTHERSON SUMI SYSTEMS LT",
  "GENERAL INS CORP OF INDIA",
  "AMBUJA CEMENTS LTD       ",
  "CIPLA LTD                ",
  "ICICI PRU LIFE INS CO LTD",
  "UPL LIMITED              ",
  "DIVI'S LABORATORIES LTD  ",
  "ICICI LOMBARD GIC LIMITED",
  "LUPIN LIMITED            ",
  "UNITED SPIRITS LIMITED   ",
  "BIOCON LIMITED.          ",
  "IDBI BANK LIMITED        ",
  "UNITED BREWERIES LTD     ",
  "PIRAMAL ENTERPRISES LTD  ",
  "SIEMENS LTD              ",
  "ZEE ENTERTAINMENT ENT LTD",
  "HINDUSTAN PETROLEUM CORP ",
  "COLGATE PALMOLIVE LTD.   ",
  "PETRONET LNG LIMITED     ",
  "CADILA HEALTHCARE LIMITED",
  "ORACLE FIN SERV SOFT LTD.",
  "P&G HYGIENE & HEALTH CARE",
  "CONTAINER CORP OF IND LTD",
  "BAJAJ HOLDINGS & INVS LTD",
  "BERGER PAINTS (I) LTD    ",
  "GLAXOSMITHKLINE CONSUMER ",
  "TORRENT PHARMACEUTICALS L",
  "NMDC LTD.                ",
  "L&T INFOTECH LIMITED     ",
  "BANK OF BARODA           ",
  "DLF LIMITED              ",
  "HDFC AMC LIMITED         ",
  "THE NEW INDIA ASSU CO LTD",
  "PUNJAB NATIONAL BANK     ",
  "INDIABULLS HSG FIN LTD   ",
  "POWER FIN CORP LTD.      ",
  "ABB INDIA LIMITED        ",
  "ACC LIMITED              ",
  "VODAFONE IDEA LIMITED    ",
  "L&T FINANCE HOLDINGS LTD ",
  "PAGE INDUSTRIES LTD      ",
  "MRF LTD                  ",
  "NHPC LTD                 ",
  "M&M FIN. SERVICES LTD    ",
  "REC LIMITED              ",
  "RBL BANK LIMITED         ",
  "KANSAI NEROLAC PAINTS LTD",
  "GLAXOSMITHKLINE PHARMA LT",
  "ASHOK LEYLAND LTD        ",
  "HINDUSTAN AERONAUTICS LTD",
  "TVS MOTOR COMPANY  LTD   ",
  "BHEL                     ",
  "ADANI TRANSMISSION LTD   ",
  "SHRIRAM TRANSPORT FIN CO.",
  "BHARAT FORGE LTD         ",
  "3M INDIA LIMITED         ",
  "LIC HOUSING FINANCE LTD  ",
  "ALKEM LABORATORIES LTD.  ",
  "CUMMINS INDIA LTD        ",
  "IDFC FIRST BANK LIMITED  ",
  "FUTURE RETAIL LIMITED    ",
  "INFO EDGE (I) LTD        ",
  "GILLETTE INDIA LTD       ",
  "SUN TV NETWORK LIMITED   ",
  "BHARAT ELECTRONICS LTD   ",
  "MUTHOOT FINANCE LIMITED  ",
  "INDRAPRASTHA GAS LTD     ",
  "STEEL AUTHORITY OF INDIA ",
  "MPHASIS LIMITED          ",
  "EXIDE INDUSTRIES LTD     ",
  "TATA POWER CO LTD        ",
  "WHIRLPOOL OF INDIA LTD   ",
  "OIL INDIA LTD            ",
  "HONEYWELL AUTOMATION IND ",
  "EMAMI LIMITED            ",
  "APOLLO HOSPITALS ENTER. L",
  "GLENMARK PHARMACEUTICALS ",
  "CANARA BANK              ",
  "CHOLAMANDALAM IN & FIN CO",
  "RAJESH EXPORTS LTD       ",
  "BANK OF INDIA            ",
  "VOLTAS LTD               ",
  "ADITYA BIRLA CAPITAL LTD.",
  "INDIABULLS VENTURES LTD  ",
  "ADANI POWER LTD          ",
  "TATA CHEMICALS LTD       ",
  "AU SMALL FINANCE BANK LTD",
  "FEDERAL BANK LTD         ",
  "GODREJ PROPERTIES LTD    ",
  "GODREJ INDUSTRIES LTD    ",
  "THE INDIAN HOTELS CO. LTD",
  "JUBILANT FOODWORKS LTD   ",
  "L&T TECHNOLOGY SER. LTD. ",
  "ADITYA BIRLA FASHION & RT",
  "OBEROI REALTY LIMITED    ",
  "ENDURANCE TECHNO. LTD.   ",
  "CASTROL INDIA LIMITED    ",
  "BALKRISHNA IND. LTD      ",
  "SUNDARAM FINANCE LTD     ",
  "GRUH FINANCE LTD.        ",
  "AIA ENGINEERING LIMITED  ",
  "PNB HOUSING FIN LTD.     ",
  "ADANI ENTERPRISES LIMITED",
  "VARUN BEVERAGES LIMITED  ",
  "SANOFI INDIA LIMITED     ",
  "MINDTREE LIMITED         ",
  "BATA INDIA LTD           ",
  "EDELWEISS FIN SERV LTD   ",
  "THE RAMCO CEMENTS LIMITED",
  "TATA COMMUNICATIONS LTD  ",
  "CITY UNION BANK LTD      ",
  "PFIZER LTD               ",
  "IIFL HOLDINGS LIMITED    ",
  "ASTRAL POLY TECH LTD     ",
  "CROMPT GREA CON ELEC LTD ",
  "BHARAT FIN INCLUSION LTD ",
  "AARTI INDUSTRIES LTD     ",
  "COROMANDEL INTERNTL. LTD ",
  "JINDAL STEEL & POWER LTD ",
  "THERMAX LTD              ",
  "TATA GLOBAL BEVERAGES LTD",
  "NATCO PHARMA LTD.        ",
  "AMARA RAJA BATTERIES LTD.",
  "SUPREME INDUSTRIES LTD   ",
  "MRPL                     ",
  "CRISIL LTD               ",
  "TORRENT POWER LTD        ",
  "WABCO INDIA LIMITED      ",
  "PI INDUSTRIES LTD        ",
  "TRENT LTD                ",
  "APOLLO TYRES LTD         ",
  "SYNGENE INTERNATIONAL LTD",
  "SRF LTD                  ",
  "EIH LIMITED              ",
  "NATIONAL ALUMINIUM CO LTD",
  "SHRIRAM CITYUNI FIN.LTD. ",
  "JUBILANT LIFE SCIENCE LTD",
  "ALEMBIC PHARMA LTD       ",
  "SUNDRAM FASTENERS LTD    ",
  "GRAPHITE INDIA LTD       ",
  "JSW ENERGY LIMITED       ",
  "INDIAN BANK              ",
  "MAX FINANCIAL SERV LTD   ",
  "NBCC (INDIA) LIMITED     ",
  "ATUL LTD                 ",
  "FORTIS HEALTHCARE LTD    ",
  "HEXAWARE TECHNOLOGIES LTD",
  "ADANI GAS LIMITED        ",
  "HATSUN AGRO PRODUCT LTD. ",
  "GUJARAT STATE PETRO LTD  ",
  "STERLITE TECHNOLOGIES LTD",
  "SKF INDIA LTD            ",
  "NLC INDIA LIMITED        ",
  "IPCA LABORATORIES LTD    ",
  "UNION BANK OF INDIA      ",
  "HEG LTD                  ",
  "GODREJ AGROVET LIMITED   ",
  "SJVN LTD                 ",
  "GUJARAT FLUOROCHEMICALS L",
  "CENTRAL BANK OF INDIA    ",
  "MOTILAL OSWAL FIN LTD    ",
  "THE PHOENIX MILLS LTD    ",
  "KIOCL LIMITED            ",
  "ITI LTD                  ",
  "CESC LTD                 ",
  "GMR INFRASTRUCTURE LTD.  ",
  "QUESS CORP LIMITED       ",
  "SOLAR INDUSTRIES (I) LTD ",
  "MAHANAGAR GAS LTD.       ",
  "DR. LAL PATH LABS LTD.   ",
  "AJANTA PHARMA LIMITED    ",
  "MAHINDRA CIE AUTO LTD    ",
  "TTK PRESTIGE LTD         ",
  "CENTURY TEXTILES LTD     ",
  "RELAXO FOOT LTD.         ",
  "GUJARAT GAS LIMITED      ",
  "SCHAEFFLER INDIA LIMITED ",
  "VARROC ENGINEERING LTD.  ",
  "ERIS LIFESCIENCES LIMITED",
  "INDIAN OVERSEAS BANK     ",
  "KAJARIA CERAMICS LTD     ",
  "BOMBAY BURMAH TRADING COR",
  "V-GUARD IND LTD.         ",
  "HSG & URBAN DEV CORPN LTD",
  "RELIANCE NIPPON L A M LTD",
  "TI FINANCIAL HOLDING LTD ",
  "FUT LIFESTYLE FASH LTD   ",
  "THOMAS COOK (INDIA) LTD  ",
  "AKZO NOBEL INDIA LIMITED ",
  "SYMPHONY LIMITED         ",
  "VINATI ORGANICS LTD      ",
  "ESCORTS INDIA LTD        ",
  "NIIT TECHNOLOGIES LTD    ",
  "FUTURE CONSUMER LIMITED  ",
  "KRBL LIMITED             ",
  "ASTER DM HEALTHCARE LTD. ",
  "MANAPPURAM FINANCE LTD   ",
  "BLUE DART EXPRESS LTD    ",
  "UCO BANK                 ",
  "GE T&D INDIA LIMITED     ",
  "PVR LIMITED              ",
  "PRESTIGE ESTATE LTD      ",
  "RELIANCE POWER LTD.      ",
  "MINDA INDUSTRIES LTD.    ",
  "CHAMBAL FERTILIZERS LTD  ",
  "ENGINEERS INDIA LTD      ",
  "RELIANCE INFRASTRUCTU LTD",
  "KARUR VYSYA BANK LTD     ",
  "CYIENT LIMITED           ",
  "VIP INDUSTRIES LTD       ",
  "JYOTHY LABS LTD          ",
  "AAVAS FINANCIERS LIMITED ",
  "ICICI SECURITIES LIMITED ",
  "CORPORATION BANK         ",
  "CARBORUNDUM UNIVERSAL LTD",
  "ALLAHABAD BANK           ",
  "JM FINANCIAL LIMITED     ",
  "TUBE INVEST OF INDIA LTD ",
  "FINOLEX INDUSTRIES LTD   ",
  "FINOLEX CABLES LTD       ",
  "AEGIS LOGISTICS LIMITED  ",
  "IDFC LIMITED             ",
  "SHEELA FOAM LIMITED      ",
  "KEC INTL. LIMITED        ",
  "DELTA CORP LIMITED       ",
  "ASAHI INDIA GLASS LIMITED",
  "VARDHMAN TEXTILES LIMITED",
  "ORIENTAL BANK OF COMMERCE",
  "WELSPUN INDIA LTD        ",
  "SUNDARAM CLAYTON LIMITED ",
  "GRINDWELL NORTON LIMITED ",
  "SYNDICATE BANK           ",
  "LAKSHMI MACHINES LTD     ",
  "BASF INDIA LTD           ",
  "VIJAYA BANK              ",
  "BLUE STAR LIMITED        ",
  "SECURITY INTELL SERVICES ",
  "CREDITACCESS GRAMEEN LTD ",
  "KALPATARU POWER TRANS. LT",
  "COFFEE DAY ENTERPRISE LTD",
  "TV18 BROADCAST LIMITED   ",
  "ADANI GREEN ENERGY LTD   ",
  "LEMON TREE HOTELS LTD    ",
  "DCB BANK LIMITED         ",
  "MERCK LIMITED            ",
  "JK CEMENT LIMITED        ",
  "RADICO KHAITAN LTD       ",
  "DCM SHRIRAM LIMITED      ",
  "TATA ELXSI LIMITED       ",
  "BAJAJ CONSUMER CARE LTD  ",
  "GE POWER INDIA LIMITED   ",
  "GUJ NAR VAL FER & CHEM L ",
  "IRB INFRA DEV LTD.       ",
  "ZENSAR TECHNOLOGIES  LTD ",
  "SUNTECK REALTY LIMITED   ",
  "ZYDUS WELLNESS LIMITED   ",
  "VST INDUSTRIES LTD       ",
  "COCHIN SHIPYARD LIMITED  ",
  "HIMADRI SPECIALITY CHEM L",
  "JOHNSON CONTROLS HITACHI ",
  "RELIANCE CAPITAL LTD     ",
  "WOCKHARDT LIMITED        ",
  "RITES LIMITED            ",
  "AVANTI FEEDS LIMITED     ",
  "GODFREY PHILLIPS INDIA LT",
  "PERSISTENT SYSTEMS LTD   ",
  "INDIAN ENERGY EXC LTD    ",
  "NCC LIMITED              ",
  "BHARAT DYNAMICS LIMITED  ",
  "TATA INVESTMENT CORP LTD ",
  "BAJAJ ELECT.LTD          ",
  "RAYMOND LTD              ",
  "VMART RETAIL LTD         ",
  "THE GE SHPG.LTD          ",
  "VAKRANGEE LIMITED        ",
  "TEAMLEASE SERVICES LTD.  ",
  "GULF OIL LUB. IND. LTD.  ",
  "SHOPPERS STOP LIMITED    ",
  "MONSANTO INDIA LIMITED   ",
  "SOBHA LIMITED            ",
  "TCNS CLOTHING CO. LIMITED",
  "CEAT LIMITED             ",
  "DILIP BUILDCON LIMITED   ",
  "ANDHRA BANK              ",
  "DISH TV INDIA LTD.       ",
  "HINDUSTAN COPPER LTD     ",
  "DEWAN HOUSING FIN CORP LT",
  "TIMKEN INDIA LTD.        ",
  "MOIL LIMITED             ",
  "ECLERX SERVICES LTD      ",
  "STRIDES PHARMA SCI LTD   ",
  "RATNAMANI MET & TUB LTD. ",
  "GUJARAT PIPAVAV PORT LTD ",
  "EQUITAS HOLDINGS LIMITED ",
  "LINDE INDIA LIMITED      ",
  "RAIN INDUSTRIES LIMITED  ",
  "SUN PHARMA ADV.RES.CO.LTD",
  "MMTC LIMITED             ",
  "ASTRAZENECA PHARMA IND LT",
  "KAVERI SEED CO. LTD.     ",
  "GALAXY SURFACTANTS LTD   ",
  "LAURUS LABS LIMITED      ",
  "OMAXE LIMITED            ",
  "STAR CEMENT LIMITED      ",
  "NARAYANA HRUDAYALAYA LTD.",
  "GUJ STATE FERT & CHEM LTD",
  "NETWORK18 MEDIA & INV LTD",
  "ELGI EQUIPMENTS LTD      ",
  "KPR MILL LTD.            ",
  "BANK OF MAHARASHTRA      ",
  "BIRLA CORPORATION LTD    ",
  "GUJARAT ALKALIES & CHEM  ",
  "MAHARASHTRA SCOOTERS LTD ",
  "IRCON INTERNATIONAL LTD  ",
  "PNC INFRATECH LTD.       ",
  "CENTURY PLYBOARDS (I) LTD",
  "CHENNAI PETROLEUM CORP LT",
  "PRISM JOHNSON LIMITED    ",
  "EID PARRY INDIA LTD      ",
  "EXCEL CROP CARE LTD      ",
  "CCL PRODUCTS (I) LTD     ",
  "TRIVENI TURBINE LIMITED  ",
  "JK LAKSHMI CEMENT LTD    ",
  "VENKY'S (INDIA) LIMITED  ",
  "RASHTRIYA CHEMICALS & FER",
  "MAHINDRA LOGISTIC LIMITED",
  "UNITED BANK OF INDIA     ",
  "ESSEL PROPACK LTD        ",
  "FINE ORGANIC IND. LTD.   ",
  "UJJIVAN FIN. SERVC. LTD. ",
  "TRIDENT LIMITED          ",
  "KARNATAKA BANK LIMITED   ",
  "FIRSTSOURCE SOLU. LTD.   ",
  "INDIABULLS REAL EST. LTD ",
  "SONATA SOFTWARE LTD      ",
  "SADBHAV ENGINEERING LTD  ",
  "ASHOKA BUILDCON LTD      ",
  "HEIDELBERGCEMENT (I) LTD ",
  "DISHMAN CARBO AMCIS LTD  ",
  "CAN FIN HOMES LTD        ",
  "IFB INDUSTRIES LTD       ",
  "BEML LIMITED             ",
  "RELIANCE COMMUNICATIONS L",
  "D.B.CORP LTD             ",
  "JUSTDIAL LTD.            ",
  "MINDA CORPORATION LTD    ",
  "GAYATRI PROJECTS LTD     ",
  "CERA SANITARYWARE LTD    ",
  "RALLIS INDIA LTD         ",
  "NESCO LTD.               ",
  "NAVIN FLUORINE INT. LTD  ",
  "SHILPA MEDICARE LTD      ",
  "MAHARASHTRA SEAMLESS LTD ",
  "BSE LIMITED              ",
  "LUX INDUSTRIES LIMITED   ",
  "JAGRAN PRAKASHAN LIMITED ",
  "JAIN IRRIGATION SYSTEMS  ",
  "PHILIPS CARBON BLACK LTD ",
  "ICRA LIMITED             ",
  "JSW HOLDINGS LIMITED     ",
  "SADBHAV INFRA PROJ LTD.  ",
  "GREAVES COTTON LTD.      ",
  "DEEPAK NITRITE LTD       ",
  "THYROCARE TECH LTD       ",
  "REDINGTON (INDIA) LTD.   ",
  "CARE RATINGS LIMITED     ",
  "INDOSTAR CAPITAL FIN LTD ",
  "KNR CONSTRU LTD.         ",
  "MAS FINANCIAL SERV LTD   ",
  "PC JEWELLER LTD          ",
  "ORIENT ELECTRIC LIMITED  ",
  "FDC LIMITED              ",
  "BRIGADE ENTER. LTD       ",
  "SHARDA CROPCHEM LTD.     ",
  "KEI INDUSTRIES LTD.      ",
  "HIMACHAL FUT COM LTD     ",
  "DENA BANK                ",
  "FEDERAL-MOGUL GOETZE (IND",
  "JINDAL SAW LIMITED       ",
  "SUPRAJIT ENGINEERING LTD ",
  "WELSPUN CORP LIMITED     ",
  "HATHWAY CABLE & DATACOM  ",
  "MAHINDRA HOLIDAYS LTD    ",
  "COX & KINGS LIMITED      ",
  "CAPLIN POINT LAB LTD.    ",
  "TECHNO ELEC & ENG CO. LTD",
  "GUJARAT MINERAL DEV CORP ",
  "JET AIRWAYS (INDIA) LTD. ",
  "RUPA & COMPANY LTD       ",
  "INDIABULLS INTEGR SER LTD",
  "INOX LEISURE LIMITED     ",
  "J B CHEMICALS AND PHARMA ",
  "MAGMA FINCORP LIMITED    ",
  "APL APOLLO TUBES LTD     ",
  "ENTERTAIN NET. IND. LTD. ",
  "THE INDIA CEMENTS LIMITED",
  "ALLCARGO LOGISTICS LTD   ",
  "DIXON TECHNO (INDIA) LTD ",
  "INFIBEAM AVENUES LIMITED ",
  "FUTURE SUPP CHAIN SOL LTD",
  "ORIENT REFRACTORIES LTD  ",
  "REPCO HOME FINANCE LTD   ",
  "SUZLON ENERGY LIMITED    ",
  "KIRLOSKAR OIL ENG LTD    ",
  "NAVNEET EDUCATION LTD    ",
  "RESPONSIVE INDUSTRIES LTD",
  "VRL LOGISTICS LIMITED    ",
  "SUVEN LIFE SCIENCES LTD  ",
  "GARWARE TECH FIBRES LTD  ",
  "GHCL LIMITED             ",
  "MISHRA DHATU NIGAM LTD   ",
  "S H KELKAR AND CO. LTD.  ",
  "KSB LIMITED              ",
  "INDIA TOUR. DEV. CO. LTD.",
  "PRATAAP SNACKS LIMITED   ",
  "JK PAPER LIMITED         ",
  "NOCIL LIMITED            ",
  "PRAJ INDUSTRIES LTD      ",
  "GUJARAT AMBUJA EXPORTS LT",
  "THE SOUTH INDIAN BANK LTD",
  "APAR INDUSTRIES LTD.     ",
  "SWAN ENERGY LIMITED      ",
  "INTELLECT DESIGN ARENA   ",
  "LA OPALA RG LIMITED      ",
  "CG POWER AND IND SOL LTD ",
  "TCI EXPRESS LIMITED      ",
  "VESUVIUS INDIA LTD       ",
  "AMBER ENTERPRISES (I) LTD",
  "BALRAMPUR CHINI MILLS LTD",
  "GRANULES INDIA LIMITED   ",
  "BOMBAY DYEING & MFG. CO L",
  "PTC INDIA LIMITED        ",
  "CAPRI GLOBAL CAPITAL LTD ",
  "IFCI LTD                 ",
  "MAX INDIA LIMITED        ",
  "HERITAGE FOODS LTD       ",
  "J & K BANK LTD.          ",
  "ARVIND LIMITED           ",
  "CENTRAL DEPO SER (I) LTD ",
  "KPIT TECHNOLOGIES LTD    ",
  "SHRIRAM PIST. & RING LTD ",
  "JAMNA AUTO IND LTD       ",
  "SUDARSHAN CHEMICAL INDS L",
  "SCHNEIDER ELECTRIC INFRA ",
  "TIME TECHNOPLAST LTD.    ",
  "VAIBHAV GLOBAL LIMITED   ",
  "BALMER LAWRIE & CO LTD   ",
  "TRANSPORT CORPN OF INDIA ",
  "TV TODAY NETWORK LTD     ",
  "TASTY BITE EATABLES LTD  ",
  "GABRIEL INDIA LTD        ",
  "TAKE SOLUTIONS LTD       ",
  "DHANUKA AGRITECH LTD     ",
  "JK TYRE & INDUSTRIES LTD ",
  "GREENLAM INDUSTRIES LTD. ",
  "SEQUENT SCIENTIFIC LTD.  ",
  "NILKAMAL LIMITED         ",
  "KOLTE PATIL DEV. LTD.    ",
  "ITD CEMENTATION INDIA LTD",
  "MAHINDRA LIFESPACE DEVLTD",
  "HIMATSINGKA SEIDE LTD    ",
  "SHREE RENUKA SUGARS LTD  ",
  "BOROSIL GLASS WORKS LTD  ",
  "JTEKT INDIA LIMITED      ",
  "NEWGEN SOFTWARE TECH LTD ",
  "WHEELS INDIA LTD         ",
  "VINDHYA TELELINKS LTD    ",
  "POLY MEDICURE LIMITED    ",
  "NAVA BHARAT VENTURES LIMI",
  "SHIPPING CORP OF INDIA LT",
  "INTERNATIONALPAPERAPPMLTD",
  "HIKAL LIMITED            ",
  "AHLUWALIA CONT IND LTD   ",
  "HINDUSTAN CONSTRUCTION CO",
  "SUPREME PETROCHEMICALS LT",
  "ADVANCED ENZYME TECH LTD ",
  "BANNARI AMMAN SUGARS LTD ",
  "INGERSOLL-RAND INDIA LTD ",
  "JINDAL STAINLESS (H) LTD ",
  "BHARAT RASAYAN LTD       ",
  "UFLEX LIMITED            ",
  "NRB BEARING LIMITED      ",
  "WEST COAST PAPER MILLS LT",
  "JAI CORP LIMITED         ",
  "TVS SRICHAKRA LIMITED    ",
  "HSIL LIMITED             ",
  "TIDE WATER OIL LTD       ",
  "HEALTHCARE GLOB. ENT. LTD",
  "PARAG MILK FOODS LTD.    ",
  "SWARAJ ENGINES LTD       ",
  "RAMCO INDUSTRIES LIMITED ",
  "OLECTRA GREENTECH LIMITED",
  "NATIONAL FERT. LTD       ",
  "LAKSHMI VILAS BANK LTD   ",
  "RELIANCE HOME FINANCE LTD",
  "AUTOMOTIVE AXLES LIMITED ",
  "INDOCO REMEDIES LTD.     ",
  "PURAVANKARA LIMITED      ",
  "MAYUR UNIQUOTERS LTD     ",
  "TATA COFFEE LIMITED      ",
  "SANDHAR TECHNOLOGIES LTD ",
  "MUTHOOT CAP SERV LTD     ",
  "PRIME FOCUS LIMITED      ",
  "MUSIC BROADCAST LIMITED  ",
  "MONNET ISPAT & ENERGY LTD",
  "FUTURE ENTERPRISES LTD   ",
  "DOLLAR INDUSTRIES LIMITED",
  "IGARASHI MOTORS INDIA LTD",
  "VA TECH WABAG LTD        ",
  "BLISS GVS PHARMA LTD     ",
  "GMM PFAUDLER LIMITED     ",
  "INDIAN HUME PIPE CO. LTD.",
  "WONDERLA HOLIDAYS LTD.   ",
  "PUNJAB & SIND BANK       ",
  "RAMKRISHNA FORGINGS LTD  ",
  "GREENPLY INDUSTRIES LTD  ",
  "SIYARAM SILK MILLS LTD   ",
  "JAIPRAKASH ASSOCIATES LTD",
  "JMC PROJECTS (I) LTD.    ",
  "EVEREADY INDS. IND.  LTD.",
  "POLYPLEX CORPORATION LTD ",
  "TATA METALIKS LTD        ",
  "PILANI INV & IND COR LTD ",
  "ALKYL AMINES CHEM. LTD   ",
  "TAMILNADU NEWSPRT & PAPER",
  "INOX WIND LIMITED        ",
  "EXCEL INDUSTRIES LTD     ",
  "ODISHA CEMENT LIMITED    ",
  "SREI INFRASTRUCTURE FINAN",
  "KEWAL KIRAN CLOTHING LTD ",
  "ORIENT CEMENT LTD.       ",
  "THE TINPLATE CO. (I) LTD ",
  "TEJAS NETWORKS LIMITED   ",
  "WELSPUN ENTERPRISES LTD. ",
  "RANE HOLDINGS LIMITED    ",
  "SHALBY LIMITED           ",
  "SAVITA OIL TECHNOLO. LTD ",
  "CAPACITE INFRAPROJECT LTD",
  "SOMANY CERAMICS LIMITED  ",
  "SUBROS LIMITED           ",
  "SANGHI INDUSTRIES LTD    ",
  "UNICHEM LABORATORIES LTD ",
  "HINDUSTAN OIL EXPLORATION",
  "JINDAL WORLDWIDE LTD     ",
  "HIL LIMITED              ",
  "LUMAX INDUSTRIES LTD     ",
  "PRAKASH INDUSTRIES LTD   ",
  "DEN NETWORKS LTD         ",
  "MEGHMANI ORGANICS LTD.   ",
  "NIIT LIMITED             ",
  "AARTI DRUGS LTD.         ",
  "SPENCER'S RETAIL LIMITED ",
  "VST TILLERS TRACTORS LTD ",
  "ACCELYA KALE SOLUTION LTD",
  "FACT LTD                 ",
  "PSP PROJECTS LIMITED     ",
  "ESAB INDIA LTD           ",
  "SUNDARAM FINANCE HOLD LTD",
  "AGRO TECH FOODS LIMITED  ",
  "BALAJI AMINES LIMITED    ",
  "STEEL STRIPS WHEELS LTD. ",
  "FAIRCHEM SPECIALITY LTD  ",
  "CENTRUM CAPITAL LIMITED  ",
  "QUICK HEAL TECH LTD      ",
  "H.G.INFRA ENGINEERING LTD",
  "JINDAL STAINLESS LIMITED ",
  "HINDUJA GLOBAL SOLS. LTD.",
  "MAJESCO LIMITED          ",
  "TECHNOCRAFT IND LTD      ",
  "BODAL CHEMICALS LTD      ",
  "SESHASAYEE PAPER & BOARDS",
  "HUHTAMAKI PPL LIMITED    ",
  "SINTEX PLASTICS TECH LTD ",
  "GIC HOUSING FINANCE LTD  ",
  "GTL INFRA.LTD            ",
  "KIRI INDUSTRIES LIMITED  ",
  "LG BALAKRISHNAN & BROS   ",
  "SATIN CREDIT NET LTD     ",
  "TEXMACO RAIL & ENG. LTD. ",
  "STERLING TOOLS LIMITED   ",
  "LT FOODS LIMITED         ",
  "POWER MECH PROJECTS LTD. ",
  "PAISALO DIGITAL LIMITED  ",
  "DPSC LIMITED             ",
  "INSECTICIDES (I) LTD     ",
  "SURYA ROSHNI LTD         ",
  "BANCO PRODUCTS (I) LTD   ",
  "MM FORGINGS LTD          ",
  "FILATEX INDIA LTD        ",
  "SAGAR CEMENTS LIMITED    ",
  "HOUSING DEV & INFRA LTD  ",
  "GOCL CORPORATION LIMITED ",
  "ORIENTAL CARBN & CHEM LTD",
  "ASHIANA HOUSING LTD      ",
  "TATA SPONGE IRON LIMITED ",
  "TAJ GVK HOTELS & RESORTS ",
  "DREDGING CORP OF INDIA   ",
  "IOL CHEM AND PHARMA LTD  ",
  "BHANSALI ENG. POLYMERS LT",
  "CESC VENTURES LIMITED    ",
  "KCP LTD                  ",
  "JBM AUTO LIMITED         ",
  "GATEWAY DISTRIPARKS LTD. ",
  "GUJ IND POW CO. LTD      ",
  "HBL POWER SYSTEMS LTD.   ",
  "HONDA SIEL POWER PROD LTD",
  "KIRLOSKAR BROTHERS LTD   ",
  "APEX FROZEN FOODS LIMITED",
  "DHAMPUR SUGAR MILLS LTD. ",
  "INEOS STYROLUTION IND LTD",
  "JINDAL POLY FILMS LIMITED",
  "SASKEN TECHNOLOGIES LTD  ",
  "TRIVENI ENGG. & INDS. LTD",
  "JAIPRAKASH POWER VEN. LTD",
  "TOURISM FINANCE CORP. OF ",
  "BLS INTL SERVS LTD       ",
  "RATTANINDIA POWER LIMITED",
  "MCLEOD RUSSEL INDIA LTD. ",
  "ALEMBIC LIMITED          ",
  "HESTER BIOSCIENCES LTD   ",
  "SAREGAMA INDIA LIMITED   ",
  "MARKSANS PHARMA LIMITED  ",
  "EIH ASSOCIATED HOTELS LTD",
  "DFM FOODS LIMITED        ",
  "TTK HEALTHCARE LIMITED   ",
  "GVK POW. & INFRA LTD.    ",
  "LUMAX AUTO TECH LTD      ",
  "DEEPAK FERTILIZERS & PETR",
  "NUCLEUS SOFTWARE EXPORTS ",
  "ACTION CONST EQUIP LTD   ",
  "MAITHAN ALLOYS LTD       ",
  "KESORAM INDUSTRIES LTD   ",
  "SHEMAROO ENTER. LTD.     ",
  "VOLTAMP TRANSFORMERS LTD ",
  "ASTEC LIFESCIENCES LTD   ",
  "USHA MARTIN LTD.         ",
  "SHANTHI GEARS LTD        ",
  "ANANT RAJ LIMITED        ",
  "CIGNITI TECHNOLOGIES LTD ",
  "MASTEK LTD               ",
  "KOKUYO CAMLIN LIMITED    ",
  "OPTIEMUS INFRACOM LTD    ",
  "MATRIMONY.COM LIMITED    ",
  "GARDEN REACH SHIP&ENG LTD",
  "SOLARA ACTIVE PHA SCI LTD",
  "PTC INDIA FIN SERV LTD   ",
  "APCOTEX INDUSTRIES LIMITE",
  "SHRIRAM EPC LTD.         ",
  "HT MEDIA LIMITED         ",
  "INDIA NIPPON ELECT  LTD  ",
  "NEULAND LAB LTD.         ",
  "I G PETROCHEMICALS LTD   ",
  "G M BREWERIES LTD        ",
  "INDO COUNT INDUSTRIES LTD",
  "BALAJI TELEFILMS LIMITED.",
  "GTPL HATHWAY LIMITED     ",
  "BAJAJ HINDUSTHAN SUGAR LT",
  "GALLANTT ISPAT LTD       ",
  "ZUARI AGRO CHEMICALS LTD ",
  "PANACEA BIOTEC LTD       ",
  "V2 RETAIL LIMITED        ",
  "MOREPEN LAB. LTD         ",
  "KAYA LIMITED             ",
  "ZEE LEARN LIMITED        ",
  "CLARIANT CHEMICALS (INDIA",
  "GEOJIT FINANCIAL SER L   ",
  "AMRUTAJAN HEALTH LTD     ",
  "SIMPLEX INFRASTRUCTURES L",
  "SHANKARA BLDG PRODUCT LTD",
  "FOSECO INDIA LTD         ",
  "MIRZA INTERNATIONAL LIMIT",
  "KALYANI STEELS LIMITED   ",
  "RELIANCE NAVAL & ENGG LTD",
  "MAHANAGAR TELEPHONE NIGAM",
  "DYNAMATIC TECHNOLOGIES   ",
  "KINGFA SCI & TEC IND LTD.",
  "UNIPLY INDUSTRIES LIMITED",
  "SML ISUZU LIMITED        ",
  "ELECTROSTEEL CASTINGS LTD",
  "MPS LIMITED              ",
  "HOTEL LEELA VENTURES LTD ",
  "MANPASAND BEVERAGES LTD. ",
  "SUNFLAG IRON AND STEEL CO",
  "SARDA ENERGY & MIN LTD   ",
  "HINDUSTAN MEDIA VENT LTD ",
  "MAN INFRA LTD            ",
  "SRIKALAHASTHI PIPES LTD  ",
  "PUNJAB CHEM & CROP PROT L",
  "ANDHRA SUGARS LTD        ",
  "RICO AUTO INDUSTRIES LTD ",
  "HIND NATL GLASS & IND LTD",
  "BF INVESTMENT LIMITED    ",
  "GENUS POWER INFRASTRU LTD",
  "SORIL INFRA RESOURCES LTD",
  "KIRLOSKAR INDUSTRIES LTD ",
  "HMT LTD -                ",
  "GODAWARI POW & ISP LTD   ",
  "INDIA GLYCOLS LTD        ",
  "UNIVERSAL CABLES LTD     ",
  "SAKUMA EXPORTS LIMITED   ",
  "MONTE CARLO FASHIONS LTD.",
  "KHADIM INDIA LIMITED     ",
  "IFGL REFRACTORIES LIMITED",
  "JKUMAR INFR.LTD.         ",
  "GATI LIMITED             ",
  "THIRUMALAI CHEMICALS LTD ",
  "SICAL LOGISTICS LIMITED  ",
  "TITAGARH WAGONS LTD.     ",
  "THE INVEST TRUST OF IND L",
  "S CHAND AND COMPANY LTD  ",
  "HINDUJA VENTURES LIMITED ",
  "RAMKY INFRA LTD          ",
  "SHARDA MOTOR INDS LTD    ",
  "BF UTILITIES LIMITED     ",
  "ALICON CASTALLOY LIMITED ",
  "DALMIA BHARAT SUG IN LTD ",
  "ORIENT PAPER AND INDS LTD",
  "ELECON ENG. CO. LTD      ",
  "TEXMACO INFRA & HOLDG LTD",
  "SEAMEC LIMITED           ",
  "NATH BIO-GENES (I) LTD   ",
  "EVEREST INDUSTRIES LTD   ",
  "EROS INTL MEDIA LTD      ",
  "SHAKTI PUMPS (I) LTD     ",
  "RUSHIL DECOR LIMITED     ",
  "MUKAND LTD.              ",
  "ARSHIYA LIMITED          ",
  "MEP INFRA. DEVELOPERS LTD",
  "IND MOTOR PART & ACC LTD ",
  "GNA AXLES LIMITED        ",
  "ORIENT HOTELS LTD        ",
  "THE STATE TRADING CORPN  ",
  "RAMCO SYSTEMS LTD.       ",
  "TATA TELESERV(MAHARASTRA)",
  "SANWARIA CONSUMER LIMITED",
  "SKIPPER LIMITED          ",
  "BHARAT ROAD NETWORK LTD  ",
  "ZEE MEDIA CORPORATION LTD",
  "NAVKAR CORPORATION LTD.  ",
  "PDS MULTINATIONAL FAS LTD",
  "UFO MOVIEZ INDIA LTD.    ",
  "KITEX GARMENTS LTD       ",
  "MUNJAL SHOWA LTD         ",
  "TATA STEEL BSL LIMITED   ",
  "ASTRA MICROWAVE LTD      ",
  "APTECH LIMITED           ",
  "KALYANI INVEST CO LTD    ",
  "ATUL AUTO LIMITED        ",
  "MAX VENTURES AND INDS LTD",
  "REPRO INDIA LIMITED      ",
  "PANAMA PETROCHEM LTD     ",
  "SUTLEJ TEXT & INDUS LTD  ",
  "HCL INFOSYSTEMS LTD      ",
  "VARDHMAN HOLDINGS LIMITED",
  "AMBIKA COTTON MILL LTD.  ",
  "GUFIC BIOSCIENCES LTD.   ",
  "UNIPHOS ENTERPRISES LTD  ",
  "FIEM INDUSTRIES LIMITED  ",
  "AJMERA REALTY & INF I LTD",
  "NELCAST LIMITED          ",
  "BHARAT BIJLEE LTD        ",
  "GANESHA ECOSPHERE LIMITED",
  "D B REALTY LIMITED       ",
  "CAMLIN FINE SCIENCES LTD ",
  "S. P. APPARELS LIMITED   ",
  "WENDT (INDIA) LIMITED    ",
  "JAYANT AGRO ORGANICS LTD ",
  "INDIAN METALS & FERRO    ",
  "R SYS INTERNATIONAL LTD  ",
  "MOLD-TEK PACKAGING LTD   ",
  "VISAKA INDUSTRIES LIMITED",
  "DATAMATICS GLOBAL SER LTD",
  "N R AGARWAL INDS LTD     ",
  "SUMMIT SECURITIES LTD    ",
  "WEIZMANN FOREX LIMITED   ",
  "THE HI-TECH GEARS LIMITED",
  "PRABHAT DAIRY LIMITED    ",
  "ZEN TECHNOLOGIES LIMITED ",
  "VIMTA LABS LIMITED       ",
  "SINTEX INDUSTRIES LTD    ",
  "MANGALAM CEMENT LTD      ",
  "NELCO LTD                ",
  "SMS PHARMACEUTICALS LTD. ",
  "SITI NETWORKS LIMITED    ",
  "RENAISSANCE JEWEL. LTD.  ",
  "ASTRON PAPER BORD MIL LTD",
  "POKARNA LIMITED          ",
  "INDO RAMA SYNTHETICS LTD ",
  "NALWA SONS INVESTMENT LTD",
  "PRECISION CAMSHAFTS LTD. ",
  "GKW LIMITED              ",
  "NCL INDUSTRIES LIMITED   ",
  "3I INFOTECH LTD.         ",
  "DECCAN CEMENTS LIMITED   ",
  "JAY BHARAT MARUTI LTD    ",
  "MIRC ELECTRONICS LTD     ",
  "CENTURY ENKA LTD         ",
  "SANDESH LTD              ",
  "PNB GILTS LIMITED        ",
  "ALOK INDUSTRIES LTD      ",
  "SREELEATHERS LIMITED     ",
  "SNOWMAN LOGISTICS LTD.   ",
  "MUNJAL AUTO IND. LTD.    ",
  "XCHANGING SOLUTIONS LTD  ",
  "BIRLA CABLE LIMITED      ",
  "ADF FOODS LIMITED        ",
  "SPIC LTD                 ",
  "IFB AGRO INDUSTRIES LTD  ",
  "GANDHI SPL. TUBES LTD    ",
  "BHAGERIA INDUSTRIES LTD  ",
  "NAGARJUN FERT AND CHE LTD",
  "SQS INDIA BFSI LIMITED   ",
  "MARATHON NXTGEN REALT LTD",
  "KDDL LIMITED             ",
  "CENTUM ELECTRONICS LTD   ",
  "THANGAMAYIL JEWELLERY LTD",
  "THE RUBY MILLS LTD       ",
  "PRECISION WIRES INDIA LTD",
  "IND TERRAIN FASHIONS LTD ",
  "MANG.CHEM.FERT.LTD       ",
  "RATTANINDIA INFRA LIMITED",
  "RSWM LIMITED             ",
  "ADOR WELDING LTD         ",
  "PATEL ENGINEERING LTD.   ",
  "RELIANCE INDUSTRIAL INFRA",
  "ASIAN GRANITO IND. LTD.  ",
  "BALLARPUR INDUSTRIES LTD ",
  "NITIN SPINNERS LIMITED   ",
  "RELIGARE ENTER. LTD.     ",
  "ORISSA MIN DEV CO LTD    ",
  "PLASTIBLENDS INDIA LTD   ",
  "SHREYAS SHIPPING & LOGIST",
  "SPECIALITY REST LTD      ",
  "RANE (MADRAS) LIMITED    ",
  "MSP STEEL & POWER LTD.   ",
  "SOM DIST & BREW LTD      ",
  "TANLA SOLUTIONS LIMITED  ",
  "PROZONE INTU PROPRTIS LTD",
  "MENON BEARINGS LIMITED   ",
  "ARVIND SMARTSPACES LTD   ",
  "SHRE PUSH CHEM & FERT LTD",
  "MANALI PETROCHEMICALS LT ",
  "GOA CARBON LIMITED       ",
  "PENNAR INDUSTRIES LTD    ",
  "DWARIKESH SUGAR IND LTD  ",
  "NACL INDUSTRIES LIMITED  ",
  "FINEOTEX CHEMICAL LIMITED",
  "VIPUL LIMITED            ",
  "ALANKIT LIMITED          ",
  "ARTEM GLOBAL LIFE SC LTD ",
  "TD POWER SYSTEMS LTD.    ",
  "RANE BRAKE LINING LTD    ",
  "ORICON ENTERPRISES LTD   ",
  "GENESYS INTL CORPN LTD   ",
  "B.L.KASHYAP & SON LTD    ",
  "BTRFLY GANDHI APPL LTD   ",
  "JAYPEE INFRATECH LTD     ",
  "PPAP AUTOMOTIVE LIMITED  ",
  "SETCO AUTOMOTIVE LTD     ",
  "GRAVITA INDIA LIMITED    ",
  "KELLTON TECH SOL LTD     ",
  "CONTROL PRINT LIMITED    ",
  "PRICOL LIMITED           ",
  "PRAXIS HOME RETAIL LTD   ",
  "MT EDUCARE LTD           ",
  "DHANLAXMI BANK LIMITED   ",
  "MADRAS FERTILISERS LTD   ",
  "SANGHVI MOVERS LTD       ",
  "63 MOONS TECHNOLOGIES LTD",
  "NECTAR LIFESCIENCES LTD. ",
  "UNITECH LIMITED          ",
  "GALLANTT METAL LIMITED   ",
  "ASIAN HOTELS (WEST) LTD  ",
  "GLOBUS SPIRITS LTD       ",
  "KAMDHENU LIMITED         ",
  "WINDSOR MACHINES LIMITED ",
  "OSWAL CHEM. & FERT. LTD. ",
  "BGR ENERGY SYSTEMS LTD   ",
  "SHIVAM AUTO.LTD          ",
  "ABAN OFFSHORE LTD.       ",
  "TRIB BHIMJI ZAVERI LTD   ",
  "CREST VENTURES LIMITED   ",
  "8K MILES SOFT SERV LTD   ",
  "COSMO FILMS LTD          ",
  "VIDHI SPCLTY F INGRDNTS L",
  "HARITA SEATING SYS. LTD  ",
  "LINCOLN PHARMA LTD       ",
  "TCPL PACKAGING LIMITED   ",
  "SALASAR TECHNO ENGG. LTD.",
  "SUBEX LIMITED            ",
  "ONMOBILE GLOBAL LTD.     ",
  "UCAL FUEL SYSTEMS LTD    ",
  "ORIENT GREEN POWER CO LTD",
  "AVADH SUG & ENERGY LTD   ",
  "INDRAPRASTHA MEDICAL CORP",
  "MAN INDUSTRIES (I) LTD.  ",
  "HPL ELECTRIC & POWER LTD ",
  "SHREE RAMA NEWSPRINT LTD ",
  "AVT NATURAL PRODUCTS LTD ",
  "AKSH OPTIFIBRE LIMITED   ",
  "RPG LIFE SCIENCES LTD    ",
  "TALWALKARS HEALTHCLUB LTD",
  "EMAMI REALTY LIMITED     ",
  "KRIDHAN INFRA LIMITED    ",
  "LINC PEN & PLASTICS LTD  ",
  "DEEP INDUSTRIES LTD      ",
  "SHALIM PAINTS LTD        ",
  "VADILAL INDUSTRIES LTD   ",
  "VARDHAMAN ACRYLICS LTD   ",
  "SKIL INFRASTRUCTURE LTD. ",
  "HERCULES HOI. LTD.       ",
  "DCW LTD                  ",
  "TVS ELECTRONICS LTD      ",
  "HINDUSTAN COMPOSITES LTD.",
  "DHUNSERI VENTURES LIMITED",
  "CEREBRA INT TECH LTD     ",
  "ADVANI HOT.& RES.(I) LTD ",
  "UTTAM SUGAR MILLS LTD.   ",
  "WALCHANDNAGAR INDUSTRIES ",
  "VIKAS ECOTECH LIMITED    ",
  "AGC NETWORKS LIMITED     ",
  "KILITCH DRUGS INDIA LTD  ",
  "ZODIAC CLOTHING CO. LTD. ",
  "CAPITAL TRUST LIMITED    ",
  "ZUARI GLOBAL LIMITED     ",
  "AKSHARCHEM INDIA LIMITED ",
  "NAHAR SPINNING MILLS LTD.",
  "OM METALS INFRAPRO LTD   ",
  "DIC INDIA LIMITED        ",
  "ORBIT EXPORTS LIMITED    ",
  "GOKALDAS EXPORTS LTD.    ",
  "ROYAL ORCHID HOTELS LTD  ",
  "EVEREST KANTO CYLINDERLTD",
  "TAMILNADU PETROPRODUCTS L",
  "5PAISA CAPITAL LIMITED   ",
  "BANNARI AM SPIN MILL LTD.",
  "VARDHMAN SPC STEEL LTD   ",
  "JAYASWAL NECO INDUSTR LTD",
  "RANE ENG VALVE LTD       ",
  "JINDAL DRILLING IND. LTD ",
  "PODDAR HOUSE & DVPT LTD  ",
  "SASTASUNDAR VENTURES LTD ",
  "N.B.I. IND. FIN. CO. LTD ",
  "PEARL GLOBAL IND LIMITED ",
  "ALPHAGEO (INDIA) LIMITED ",
  "KANORIA CHEMICALS & INDUS",
  "GANESH HOUSING CORP LTD  ",
  "GULSHAN POLYOLS LIMITED  ",
  "GP PETROLEUMS LIMITED    ",
  "ASIAN HOTELS (NORTH) LTD ",
  "ORIENT ABRASIVE LTD      ",
  "SELAN EXPLO. TECH LTD    ",
  "NILA INFRASTRUCTURES LTD ",
  "VASCON ENGINEERS LTD     ",
  "RUCHIRA PAPERS LIMITED   ",
  "SAKSOFT LIMITED          ",
  "ROSSELL INDIA LIMITED    ",
  "AURIONPRO SOLN LTD       ",
  "ALLSEC TECHNOLOGIES LTD  ",
  "PARSVNATH DEVELOPER LTD  ",
  "NANDAN DENIM LIMITED     ",
  "ANJANI PORTLAND CEM LTD  ",
  "ASIAN HOTELS (EAST) LTD  ",
  "MERCATOR LIMITED         ",
  "HUBTOWN LIMITED          ",
  "BHARAT WIRE ROPES LTD.   ",
  "FUTURE MKT NETWORKS LTD  ",
  "LIBERTY SHOES LTD        ",
  "KOTHARI PRODUCTS LTD     ",
  "R.P.P INFRA PROJECTS LTD ",
  "BHARTIYA INTERNATIONAL   ",
  "PTL ENTERPRISES LTD      ",
  "THEMIS MEDICARE LTD.     ",
  "ADITYA BIRLA MONEY LTD   ",
  "D-LINK INDIA LTD         ",
  "PENINSULA LAND LIMITED   ",
  "SREE RAYALSEEMA HHP LTD. ",
  "NITCO LIMITED            ",
  "TALBROS AUTO. COMP. LTD  ",
  "ESSAR SHIPPING LTD       ",
  "INDO-NATIONAL LIMITED    ",
  "PREMIER EXPLOSIVES LTD   ",
  "BOMBAY RAYON FASHIONS LTD",
  "KARDA CONSTRUCTIONS LTD  ",
  "ESTER INDUSTRIES LTD     ",
  "SHIVA TEXYARN LIMITED    ",
  "IL&FS TRANS NET LTD      ",
  "OPTO CIRCUITS (I) LTD.   ",
  "APOLLO MICRO SYSTEMS LTD ",
  "TIL LTD                  ",
  "SWELECT ENERGY SYS LTD   ",
  "SANGAM (INDIA) LTD       ",
  "CANTABIL RETAIL LTD      ",
  "RAJ TV NETWORK LTD       ",
  "AXISCADES ENGG TECH LTD  ",
  "APOLLO SINDOORI HOTEL LTD",
  "SARLA PERF. FIBERS LTD   ",
  "GEECEE VENTURES LIMITED  ",
  "MANAKSIA LTD.            ",
  "RUCHI SOYA INDUSTRIES LTD",
  "ASHAPURA MINECHEM LTD    ",
  "NDTV LTD                 ",
  "EMKAY GLOBAL FIN SERV LTD",
  "KABRA EXTRUSION TECHNIK L",
  "ORIENT BELL LIMITED      ",
  "ASAHI SONGWON COLOR LTD  ",
  "GOKUL AGRO RESOURCES LTD ",
  "JAYSHREE TEA & INDUSTRIES",
  "TRIGYN TECHNOLOGIES LTD  ",
  "SPICE MOBILITY LIMITED   ",
  "RADHA MADHAV CO. LTD.    ",
  "EIMCO ELECON (INDIA) LTD ",
  "ALBERT DAVID LIMITED     ",
  "GOKUL REFOILS & SOLV LTD ",
  "TILAKNAGAR INDUSTRIES LTD",
  "ARMAN FIN SERV LTD       ",
  "GUJ. APOLLO IND. LTD.    ",
  "CHEMFAB ALKALIS LIMITED  ",
  "SIL INVESTMENTS LIMITED  ",
  "ARIHANT SUPERSTRUCT LTD  ",
  "INTRASOFT TECH. LTD      ",
  "GENUS P&B LIMITED        ",
  "VIVIMED LABS LIMITED     ",
  "ADHUNIK INDUSTRIES LTD   ",
  "HI-TECH PIPES LIMITED    ",
  "PARAMOUNT COMM LTD       ",
  "STAR PAPER MILLS LTD     ",
  "PENNAR ENG BLDG SYS LTD  ",
  "CMI LIMITED              ",
  "PIONEER DIST LTD         ",
  "IND SWIFT LABORATORIES LT",
  "VIP CLOTHING LIMITED     ",
  "GUJARAT SIDHEE CEM. LTD. ",
  "SALZER ELECTRONICS LTD   ",
  "EMMBI INDUSTRIES LIMITED ",
  "STEL HOLDINGS LIMITED    ",
  "DONEAR IND. LIMITED      ",
  "GOLDIAM INTERNATIONAL LTD",
  "KCP SUGAR IND CORP LTD.  ",
  "PUDUMJEE PAPER PRO. LTD  ",
  "VLS FINANCE LTD          ",
  "HOV SERVICES LTD         ",
  "ELECTROTHERM (I) LTD.    ",
  "GSS INFOTECH LIMITED     ",
  "ANSAL PROP & INFRA LTD   ",
  "KOPRAN LTD               ",
  "NAHAR INDS ENT LTD       ",
  "HIND RECTIFIER LIMITED   ",
  "HITECH CORPORATION LTD   ",
  "ASHIMA LTD               ",
  "A2Z INFRA ENGINEERING LTD",
  "BPL LTD                  ",
  "SMARTLINK HOLDINGS LTD   ",
  "IL&FS INVESTMENT MANAGERS",
  "OMAX AUTOS LTD           ",
  "PODDAR PIGMENTS LIMITED  ",
  "CL EDUCATE LIMITED       ",
  "PITTI ENGINEERING LIMITED",
  "SMS LIFESCIENCES (I) LTD ",
  "AGARWAL INDS CORP LTD.   ",
  "RAMA STEEL TUBES LIMITED ",
  "VISHNU CHEMICALS LIMITED ",
  "CUPID LIMITED            ",
  "SKM EGG PROD EXPORT(I) LT",
  "SHREYANS INDUSTRIES LTD  ",
  "NEXT MEDIAWORKS LIMITED  ",
  "DHUNSERI INVESTMENTS LTD ",
  "URJA GLOBAL LIMITED      ",
  "SHIRPUR GOLD REFINERY LTD",
  "PIL ITALICA LIFESTYLE LTD",
  "JAI BALAJI INDUSTRIES LTD",
  "SPL INDUSTRIES LIMITED   ",
  "THE BYKE HOSPITALITY LTD ",
  "MAWANA SUGARS LIMITED    ",
  "DHUNSERI TEA & IND. LTD. ",
  "HINDUSTAN MOTORS LIMITED ",
  "ECE INDUSTRIES LTD.      ",
  "UTTAM GALVA STEELS LTD   ",
  "ORIENT PRESS LIMITED     ",
  "GOODLUCK INDIA LIMITED   ",
  "AYM SYNTEX LIMITED       ",
  "KWALITY LIMITED          ",
  "TRANS & RECTI. LTD       ",
  "KARUTURI GLOBAL LIMITED  ",
  "JBF INDUSTRIES LTD       ",
  "NAHAR CAP & FIN.         ",
  "INDUSTRIAL INV TRUST LTD ",
  "UNITED NILGIRI TEA LTD   ",
  "BRIGHTCOM GROUP LIMITED  ",
  "ATLANTA LIMITED          ",
  "INSPIRISYS SOLUTIONS LTD ",
  "MAHA RASHTRA APEX COPR. L",
  "LOVABLE LINGERIE LTD     ",
  "CONSO. FIN. & HOLD. LTD. ",
  "KREBS BIOCHEMICALS & IND ",
  "JMT AUTO LIMITED         ",
  "HARRISON MALAYALAM LTD   ",
  "PG ELECTROPLAST LTD      ",
  "MBL INFRASTRUCTURES LTD  ",
  "BINANI INDUSTRIES LTD    ",
  "IL&FS ENG AND CONS CO LTD",
  "KAKATIYA CEM SUGAR &IND L",
  "SAKTHI SUGARS LTD        ",
  "THE UGAR SUGAR WORKS LTD ",
  "SIGNET INDUSTRIES LIMITED",
  "SUPERHOUSE LIMITED       ",
  "VINYL CHEMICALS (I) LTD. ",
  "MAHAMAYA STEEL INDS LTD  ",
  "SUNDARAM BRAK LININGS LTD",
  "GPT INFRAPROJECTS LTD    ",
  "DYNEMIC PRODUCTS LIMITED ",
  "ANDHRA CEMENTS LIMITED   ",
  "DUCON INFRATECHNOLOGIES L",
  "TECHNOFAB ENG LTD        ",
  "AUTOLINE INDUSTRIES LTD  ",
  "20 MICRONS LTD           ",
  "FLEXITUFF VENTURES INT L ",
  "T T LIMITED              ",
  "JUBILANT INDUSTRIES LTD  ",
  "TCI DEVELOPERS LIMITED   ",
  "BHARAT GEARS LTD.        ",
  "DE NORA INDIA LIMITED    ",
  "MANAKSIA STEELS LTD      ",
  "DCM LTD                  ",
  "NOIDA TOLL BRIDGE CO LTD ",
  "CAREER POINT LIMITED     ",
  "INVENTURE GRO & SEC LTD  ",
  "UMANG DAIRIES LIMITED    ",
  "KOTHARI PETROCHEM LTD    ",
  "MOLD-TEK TECHNOLOGIES LTD",
  "GRP LIMITED              ",
  "REVATHI EQUIPMENT LTD    ",
  "ASPINWALL & CO LTD       ",
  "JHS SVEND. LAB. LTD      ",
  "TALWALKAR FITNESS LTD    ",
  "LEEL ELECTRICALS LIMITED ",
  "KOHINOOR FOODS LIMITED   ",
  "HEXA TRADEX LIMITED      ",
  "NILA SPACES LIMITED      ",
  "CINELINE INDIA LIMITED   ",
  "BANSWARA SYNTEX LIMITED  ",
  "TRF LIMITED              ",
  "ARIES AGRO LTD.          ",
  "UJAAS ENERGY LIMITED     ",
  "MAZDA LIMITED            ",
  "SHYAM CENTURY FERROUS LTD",
  "PONNIE SUGARS (ERODE) LTD",
  "VETO SWITCHGEAR CABLE LTD",
  "GILLANDERS ARBUTHNOT LTD ",
  "ARROW GREENTECH LIMITED  ",
  "ELGI RUBBER CO. LTD      ",
  "COMPUAGE INFOCOM LTD     ",
  "PRIME SECURITIES LIMITED ",
  "MARAL OVERSEAS LTD       ",
  "KIRLOSKAR ELECTRIC CO LTD",
  "INDO TECH TRANSFORM LTD. ",
  "IVP LTD                  ",
  "ISMT LIMITED             ",
  "SPML INFRA LIMITED       ",
  "GINNI FILAMENTS LTD      ",
  "COUNTRY CLUB HOSP HOL LTD",
  "MODI RUBBER LTD          ",
  "KALYANI FORGE LIMITED    ",
  "COMMERCIAL ENG & CO. LTD ",
  "OSWAL AGRO MILLS LTD     ",
  "CYBERTECH SYSTEMS & SOFTW",
  "BHAGYANAGAR INDIA LIMITED",
  "JOCIL LIMITED            ",
  "MANGALAM DRUG & CHEM LTD ",
  "SAGARDEEP ALLOYS LIMITED ",
  "BAL PHARMA LTD.          ",
  "TPL PLASTECH LIMITED     ",
  "KAMAT HOTELS (I) LTD     ",
  "MURUDESHWAR CERAMICS LTD ",
  "UTTAM VALUE STEELS LTD   ",
  "STANDARD INDUSTRIES LTD. ",
  "DAMODAR INDUSTRIES LTD   ",
  "JULLUNDUR MOT AGENCY LTD ",
  "MOTOR & GENERAL FINANCE L",
  "MINDTECK (INDIA) LIMITED ",
  "ARCHIES LTD              ",
  "BHAGYANAGAR PRO LTD      ",
  "VIDEOCON INDUSTRIES LIMIT",
  "INTENSE TECHNOLOGIES LTD ",
  "ONWARD TECHNOLOGIES LTD  ",
  "PUNJ LLOYD LIMITED       ",
  "NAHAR POLY FILMS LIMITED ",
  "LLOYDS STEELS IND. LTD.  ",
  "GTL LTD                  ",
  "THE WESTERN INDIA PLY LTD",
  "SICAGEN INDIA LIMITED    ",
  "AUTOMOTIVE STAMPINGS & AS",
  "MAGADH SUGAR & ENERGY LTD",
  "CAMBRIDGE TECH ENTER LTD ",
  "CIMMCO LIMITED           ",
  "MANUGRAPH INDIA LIMITED  ",
  "GARDEN SILK MILLS LTD    ",
  "ROLTA INDIA LTD          ",
  "VISA STEEL LIMITED       ",
  "PVP VENTURES LIMITED     ",
  "TIPS INDUSTRIES LIMITED  ",
  "ARCHIDPLY IND. LTD       ",
  "HIGH GROUND ENTP LTD     ",
  "NITESH ESTATES LTD       ",
  "MUKTA ARTS LIMITED       ",
  "B.A.G FILMS AND MEDIA LTD",
  "MORARJEE TEXTILES LIMITED",
  "BROOKS LAB LIMITED       ",
  "JAGSONPAL PHARMACEUTICALS",
  "NAGA DHUNSERI GROUP LTD  ",
  "AMJ LAND HOLDINGS LIMITED",
  "GYSCOAL ALLOYS LTD       ",
  "MCNALLY BH. ENG. CO.LTD  ",
  "GLOBAL VEC HELICORP LTD  ",
  "ADLABS ENTERTAINMENT LTD.",
  "STEEL EXCHANGE INDIA LTD ",
  "CONS. CONST. CONSORT LTD ",
  "COMPUCOM SOFTWARE LTD    ",
  "R. S. SOFTWARE (INDIA) LI",
  "INDOSOLAR LIMITED        ",
  "MRO-TEK REALTY LIMITED   ",
  "SAMBHAAV MEDIA LTD       ",
  "CORDS CABLE INDUS LTD.   ",
  "JVL AGRO INDUSTRIES LTD  ",
  "IZMO LIMITED             ",
  "DOLPHIN OFF. ENT IND LTD.",
  "ARO GRANITE IND. LTD.    ",
  "BANG OVERSEAS LTD.       ",
  "CORAL INDIA FIN & HOUS LT",
  "KOTHARI SUG & CHEM LTD.  ",
  "WEIZMANN LTD             ",
  "LOKESH MACHINES LTD      ",
  "ANSAL HOUSING LIMITED    ",
  "PRECOT MERIDIAN LIMITED  ",
  "K M SUGAR MILLS LTD      ",
  "EON ELECTRIC LIMITED     ",
  "PIRAMAL PHYTOCARE LIMITED",
  "IVRCL LIMITED            ",
  "REMSONS INDUSTRIES LTD   ",
  "ARSS INFRA PROJ. LTD     ",
  "LYKA LABS LTD            ",
  "GOLDEN TOBACCO LIMITED   ",
  "PATEL INT. LOG. LTD      ",
  "THE MANDHANA RET VENT LTD",
  "PRESSMAN ADVERTISING LTD ",
  "WEBSOL ENERGY SYSTEM LTD ",
  "KESAR TER & INFRA LTD    ",
  "GAMMON INFRA PROJ LTD.   ",
  "MOHOTA INDUSTRIES LIMITED",
  "ROLLATAINERS LIMITED     ",
  "SURANA TELECOM AND POW LT",
  "BLUE COAST HOTELS LIMITED",
  "TAINWALA CHEMICAL AND PLA",
  "SURYALAKSHMI COT MIL LTD ",
  "C&C CONST. LTD.          ",
  "ALLIED DIGITAL SERV. LTD ",
  "RUCHI INFRASTRUCTURE LTD ",
  "OIL COUNTRY TUBULAR LTD  ",
  "MAAN ALUMINIUM LIMITED   ",
  "TERA SOFTWARE LIMITED    ",
  "PIONEER EMBROIDERIES LTD ",
  "ARCOTECH LIMITED         ",
  "PRAJAY ENG. SYN. LTD.    ",
  "DILIGENT MEDIA CORP LTD. ",
  "LANDMARK PR.DEV.CO.LTD   ",
  "RAJSHREE SUGAR & CHEMICAL",
  "ALMONDZ GLOBAL SEC LTD   ",
  "WILLIAMSON MAGOR         ",
  "ASSAM COMPANY INDIA LTD  ",
  "AARVEE DEN. & EXP. LTD   ",
  "DQ ENTERTAINMENT INT LTD ",
  "CENTURY EXTRUSIONS LTD   ",
  "TEXMO PIPE & PRODUCTS LTD",
  "LOTUS EYE HOSP & INST L  ",
  "NRB INDUS. BEARINGS LTD. ",
  "BEDMUTHA INDUST LTD      ",
  "ALPA LABORATORIES LTD    ",
  "THE GROB TEA COMPANY LTD ",
  "SUNDARAM MULTI PAP LTD   ",
  "LASA SUPERGENERICS LTD   ",
  "AUSOM ENTERPRISE LIMITED ",
  "PREMIER POLYFILM LTD     ",
  "SUNIL HITECH ENGR. LTD   ",
  "WELSPUN INV & COMM LTD   ",
  "KSK ENERGY VENTURES LTD  ",
  "MALU PAPER MILLS LIMITED ",
  "ALKALI METALS LTD        ",
  "CIL NOVA PETROCHEM LTD   ",
  "ESS DEE ALUM LTD         ",
  "S.A.L. STEEL LTD.        ",
  "ATLAS CYCLE (HARYANA) LTD",
  "ENERGY DEVE. CO.LTD      ",
  "PRAKASH CONSTROWELL LTD  ",
  "BSL LTD                  ",
  "THE P K TEA PROD CO LTD  ",
  "VENUS REMEDIES LIMITED   ",
  "SUPREME INFRA. LTD.      ",
  "TOKYO PLAST INTL LTD     ",
  "BEARDSELL LIMITED        ",
  "SATHAVAHANA ISPAT LTD    ",
  "ANIK INDUSTRIES LTD      ",
  "DELTA MAGNETS LIMITED    ",
  "SHIVA MILLS LIMITED      ",
  "TGB BANQUETS&HOTELS LTD  ",
  "RANA SUGARS LTD.         ",
  "KANANI INDUSTRIES LTD    ",
  "INDIAN CARD CLOTHING CO. ",
  "GANGES SECURITIES LIMITED",
  "INDOWIND ENERGY LTD      ",
  "MADHUCON PROJECTS LTD.   ",
  "XPRO INDIA LIMITED       ",
  "TIJARIA POLYPIPES LTD    ",
  "ASHAPURA INTI FASHION LTD",
  "SEL MANU. CO. LTD.       ",
  "MANAK ALUMINIUM CO. LTD. ",
  "MAN COAT METAL & IND LTD ",
  "BALKRISHNA PAPER MILLS L ",
  "JITF INFRALOGISTICS LTD  ",
  "NORTH EAST CARRY CORP LTD",
  "BKM INDUSTRIES LIMITED   ",
  "AMD INDUSTRIES LIMITED   ",
  "TARMAT LIMITED           ",
  "OMKAR SPL CHEM LTD       ",
  "CELEBRITY FASHIONS LTD   ",
  "AUTOLITE (INDIA) LIMITED ",
  "INDBANK MERCH BANK       ",
  "PALASH SECURITIES LTD    ",
  "BIGBLOC CONSTRUCTION LTD ",
  "NITIN FIRE PROT IND. LTD.",
  "PROVOGUE (INDIA) LIMITED ",
  "SURANA SOLAR LIMITED     ",
  "WANBURY LIMITED          ",
  "SIMBHAOLI SUGARS LTD.    ",
  "SHARON BIO-MEDI LTD      ",
  "LAMBODHARA TEXTILES LTD. ",
  "SUPER SPINNING MILLS LTD ",
  "MADHAV MARBLE & GRANITE  ",
  "SUMEET IND LIMITED       ",
  "SANGHVI FOR & ENG LTD    ",
  "BARAK VALLEY CEM. LTD.   ",
  "VISESH INFOTECNICS LTD   ",
  "SHAH ALLOYS LIMITED      ",
  "JIND POL INV & FIN CO LTD",
  "ORCHID PHARMA LIMITED    ",
  "SALONA COTSPIN LTD.      ",
  "PALRED TECHNOLOGIES LTD  ",
  "KEYNOTE CORP. SERV. LTD  ",
  "METALYST FORGINGS LIMITED",
  "DIAMOND POWER INFRA LTD  ",
  "TREJHARA SOLUTIONS LTD   ",
  "DHARANI SUGARS & CHEMICAL",
  "CASTEX TECHNOLOGIES LTD. ",
  "SOMI CONVEYOR BELT. LTD. ",
  "DIGJAM LIMITED           ",
  "IMP POWERS LTD           ",
  "FCS SOFTWARE SOLN. LTD.  ",
  "MANGALAM TIMBER PRODUCTS ",
  "MCDOWELL HOLDINGS LTD.   ",
  "PARENTERAL DRUGS LTD     ",
  "PATSPIN INDIA LTD        ",
  "SHREE RAMA MULTI TECH LTD",
  "AGRI-TECH (INDIA) LIMITED",
  "ORIENTAL TRIMEX LTD      ",
  "GLOBAL OFFSHORE SERV LTD ",
  "MEGASOFT LTD             ",
  "IND-SWIFT LIMITED        ",
  "STI INDIA LTD            ",
  "ARROW TEXTILES LIMITED   ",
  "ADROIT INFOTECH LIMITED  ",
  "REFEX INDUSTRIES LIMITED ",
  "EMCO LIMITED             ",
  "KERNEX MICROSYS(I) LTD   ",
  "CINEVISTA LIMITED        ",
  "OCL IRON AND STEEL LTD   ",
  "BANARAS BEADS LTD        ",
  "VASWANI IND LTD          ",
  "ARIHANT FOUN & HOU LTD   ",
  "DB (INT) STOCK BROKERS   ",
  "KESAR ENTERPRISES LTD.   ",
  "INDO THAI SEC LTD        ",
  "NATIONAL STEEL & AGRO IND",
  "NAGREEKA EXPORTS LTD.    ",
  "BHANDARI HOSIERY EXP LTD ",
  "TIMES GUARANTY LIMITED   ",
  "BLB LIMITED              ",
  "JINDAL PHOTO LIMITED     ",
  "PRITISH NANDY COMMUNICATI",
  "LAKSHMI FIN IND CORP LTD ",
  "EDUCOMP SOLUTIONS LTD    ",
  "E-LAND APPAREL LIMITED   ",
  "BURNPUR CEMENT LTD.      ",
  "NK INDUSTRIES LTD        ",
  "GOLDSTONE TECHNOLOGIES LT",
  "PETRON ENGG CONSTRUCT LTD",
  "ORTIN LABORATORIES LTD   ",
  "CALIFORNIA SOFTWARE CO LT",
  "MUKAND ENGINEERS LIMITED ",
  "THIRU AROORAN SUGARS LTD.",
  "LYPSA GEMS & JEWEL LTD   ",
  "NAGARJUNA OIL REFINERY   ",
  "ADHUNIK METALIKS LTD.    ",
  "VARDHMAN POLYTEX LTD.    ",
  "EASUN REYROLLE LTD       ",
  "ZENITH EXPORTS LTD       ",
  "HILTON METAL FORGING LTD ",
  "21ST CENTURY MGMT SERVICE",
  "JINDAL COTEX LTD         ",
  "ZICOM ELECT SEC SYS LTD  ",
  "PREMIER LIMITED          ",
  "MAGNUM VENTURES LTD.     ",
  "BARTRONICS INDIA LIMITED ",
  "GAYATRI HIGHWAYS LIMITED ",
  "DYNACONS SYS & SOLN LTD  ",
  "EXCEL REALTY N INFRA LTD ",
  "KARMA ENERGY LIMITED     ",
  "KSS LIMITED              ",
  "STAMPEDE CAPITAL LTD     ",
  "PEARL POLYMERS LTD       ",
  "STL GLOBAL LIMITED       ",
  "RAVI KUMAR DIST. LTD.    ",
  "HISAR METAL IND. LIMITED ",
  "ZODIAC JRD-MKJ LTD       ",
  "CUBEX TUBINGS LTD        ",
  "MIC ELECTRONICS LIMITED  ",
  "BAFNA PHARMACEUTICALS L  ",
  "SHILPI CABLE TECH LTD    ",
  "BIL ENERGY SYSTEMS LTD   ",
  "3P LAND HOLDINGS LIMITED ",
  "TCI FINANCE LTD          ",
  "SALORA INTERNATIONAL LTD ",
  "ABM INTERNATIONAL LTD    ",
  "USHER AGRO LTD           ",
  "S.E. POWER LIMITED       ",
  "ONELIFE CAP ADVISORS LTD ",
  "MASK INVESTMENTS LIMITED ",
  "VISAGAR POLYTEX LTD      ",
  "TREE HOUSE EDU LTD       ",
  "ROHIT FERRO-TECH LIMITED ",
  "VIJAY SHANTHI BUILD LTD  ",
  "NAGREEKA CAP & INFR.LTD  ",
  "COUNTRY CONDO'S LIMITED  ",
  "EUROTEX INDUSTRIES LTD   ",
  "EMPEE DISTI. LTD.        ",
  "GTN INDUSTRIES LIMITED   ",
  "KHANDWALA SECURITIES LTD ",
  "BSEL INFRASTRUCTURE REALT",
  "SANCO INDUSTRIES LIMITED ",
  "CELESTIAL BIOLABS LTD    ",
  "EASTERN SILK INDUSTRIES L",
  "SRI ADHIKARI BROS.       ",
  "JYOTI STRUCTURES LTD     ",
  "KHAITAN (INDIA)LTD.      ",
  "UNITY INFRAPROJECTS LTD  ",
  "MOHIT INDUSTRIES LTD     ",
  "VIMAL O & F LTD          ",
  "RADAAN MEDIAWORKS (I) LTD",
  "VICEROY HOTELS LIMITED   ",
  "SOMA TEXTILES & INDUST LT",
  "SPENTEX IND. LTD         ",
  "KAVVERI TEL. LTD.        ",
  "PSL LIMITED              ",
  "ORTEL COMMUNICATIONS LTD ",
  "MVL LIMITED              ",
  "PARABOLIC DRUGS LTD      ",
  "LAKSHMI PRE SCRE LTD     ",
  "GTN TEXTILES LIMITED     ",
  "TV VISION LIMITED        ",
  "GOENKA DIAMOND&JEWELS LTD",
  "MANDHANA INDUS. LTD      ",
  "SPLENDID METAL PRODUCTS L",
  "KHAITAN ELECTRICALS LTD  ",
  "REGENCYCERAMICS-LTD      ",
  "TRANSWARRANTY FIN. LTD.  ",
  "TECHINDIA NIRMAN LIMITED ",
  "BHARATI DEF & INFRA LTD  ",
  "ABG SHIPYARD LTD         ",
  "SRI HAVISHA HOSP & INFR L",
  "ZENITH BIRLA (INDIA) LTD ",
  "VIJI FINANCE LIMITED     ",
  "RAINBOW PAPERS LTD       ",
  "SHEKHAWATI POLY-YARN LTD.",
  "RAJVIR INDUSTRIES LIMITED",
  "PBA INFRASTRUCTURE LTD.  ",
  "METKORE ALLOYS & IND LTD ",
  "W.S.INDUSTRIES (I) LTD.  ",
  "CYBER MEDIA (INDIA) LTD. ",
  "BLUE BLENDS (I) LTD      ",
  "NEUEON TOWERS LIMITED    ",
  "SYNCOM HEALTHCARE LTD    ",
  "PRADIP OVERSEAS LTD      ",
  "GUJARAT RAFFIA INDUST LTD",
  "BIOFIL CHEM & PHARMA LTD.",
  "CHROMATIC INDIA LTD      ",
  "SRS LIMITED              ",
  "SHYAM TELECOM LIMITED    ",
  "NIRAJ ISPAT IND LTD      ",
  "HIND SYNTEX LTD          ",
  "INTEGRA GARMENT & TEX LTD",
  "TARAPUR TRANSFORMERS LTD ",
  "NORBEN TEA & EXPORTS LTD ",
  "TANTIA CONST LTD         ",
  "ANTARCTICA LTD           ",
  "WINSOME YARNS LIMITED    ",
  "KALYANI COMMERCIALS LTD  ",
  "INTERNATIONAL CONST LTD  ",
  "ANKIT MET & POW LTD      ",
  "NU TEK INDIA LTD         ",
  "HB STOCKHOLDINGS LIMITED ",
  "CREATIVE EYE LIMITED     ",
  "PROSEED INDIA LIMITED    ",
  "PRAKASH STEELAGE LTD     ",
  "SUJANA UNI. INDS. LTD.   ",
  "EURO CERAMICS LTD        ",
  "GANGOTRI TEXTILES LTD.   ",
  "RAMGOPAL POLYTEX LIMITED ",
  "SPACENET ENTERS IND LTD  ",
  "USHA MARTIN EDU & SOL LTD",
  "BHARATIYA GLOBAL INFO LTD",
  "RAJDARSHAN INDUSTRIES LTD",
  "JAIN STUDIOS LIMITED     ",
  "GI ENGINEERING SOLNS LTD ",
  "RAJ RAYON INDUSTRIES LTD ",
  "TAMILNADU TELECOMMUNICATI",
  "SAB EVENTS & GOVERNANCE  ",
  "ALCHEMIST LTD            ",
  "KAUSHALYA INFRA DEV LTD. ",
  "IMPEX FERRO TECH LIMITED ",
  "HOTEL RUGBY LTD          ",
  "THOMAS SCOTT (INDIA) LTD ",
  "DCM FINANCIAL SERVICES LT",
  "PAE LIMITED              ",
  "JAIHIND PROJECTS LTD     ",
  "GEMINI COMM. LTD         ",
  "EURO MULTIVISION LTD     ",
  "TARA JEWELS LIMITED      ",
  "JIK INDUSTRIES LTD       ",
  "XL ENERGY LIMITED        ",
  "MELSTAR INFORMATION TECH ",
  "QUINTEGRA SOLUTIONS LTD. ",
  "SITA SHREE FOOD PROD LTD ",
  "ANG INDUSTRIES LIMITED   ",
  "SHREE GANESH FORG. LTD   ",
  "CURA TECHNOLOGIES LTD    ",
  "RAMSARUP INDUSTRIES LTD",
];

function NavigationBar() {
  const [searchText, setSearchText] = useState("");
  const expand = "lg";
  return (
    <>
      <Navbar key={expand} bg="danger" expand={expand} className="">
        <Container>
          <Navbar.Brand href="/">Navbar Offcanvas</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Offcanvas
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/page1">Top Stocks</Nav.Link>
              </Nav>
              <Form className="d-flex position-relative">
                <FormControl
                  type="search"
                  placeholder="Search"
                  className="me-2 "
                  aria-label="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                {searchText.length > 0 && (
                  <ul className="position-absolute top-100 bg-danger end-0 me-3 rounded mt-1">
                    {data
                      .filter((name) =>
                        name.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .slice(0, 5)
                      .map((name) => (
                        <li
                          key={name}
                          className="me-3 my-3"
                          onClick={() => {
                            setSearchText("");
                          }}
                        >
                          <Link to={name} className="text-info">
                            {name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}

                {console.log(searchText)}
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
