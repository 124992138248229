import React from "react";
import { Link, Outlet } from "react-router-dom";

function Homepage() {
  return (
    <div>
      Homepage
    </div>
  );
}

export default Homepage;
